"use client"

import { IntegrationScript } from "src/integrations/lib/IntegrationScript"
import { CCM19_API_KEY, CCM19_DOMAIN } from "./config"
import { useCcm19EventHandling } from "./useConsented"

const src = `https://cloud.ccm19.de/app.js?apiKey=${CCM19_API_KEY}&domain=${CCM19_DOMAIN}`

export const Ccm19Tag = () => {
  useCcm19EventHandling()

  return <IntegrationScript id="ccm19" src={src} />
}
