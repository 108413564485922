"use client"

import { ReactNode, createContext, useContext } from "react"

export type ModalContext = {
  close: () => void
}

const ModalContext = createContext<ModalContext | null>(null)

export type ModalProviderProps = {
  children?: ReactNode
  close: () => void
}

export const ModalProvider = ({ children, close }: ModalProviderProps) => {
  return <ModalContext.Provider value={{ close }}>{children}</ModalContext.Provider>
}

export const useModal = () => {
  const context = useContext(ModalContext)
  if (context === null) {
    throw new Error("useModal must be used within a ModalProvider")
  }
  return context
}
