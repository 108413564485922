"use client"

import { useState } from "react"
import { InfoAlert } from "src/components/(account)/account/components/InfoAlert"
import { CloseButton } from "src/components/common/CloseButton"
import { Modal } from "src/components/common/Modal"
import { ArrowRightIcon } from "src/components/icons"
import { CountryFlag } from "src/components/layout/header/CountryFlag"
import { useIsClient } from "src/hooks/useIsClient"
import { getRelativeUrlWithoutLocale } from "src/localization/client"
import { DEFAULT_COUNTRY_CODE } from "src/localization/localization"
import { localizeText } from "src/localization/utils"
import { StorefrontCountry } from "src/types/storefront"

export type CountrySelectPopoverProps = {
  countryCode: string
  countries: StorefrontCountry[]
}

export const CountrySelectPopover = ({ countryCode, countries }: CountrySelectPopoverProps) => {
  const [open, setOpen] = useState(false)
  const isClient = useIsClient()

  const T = localizeText(text)
  const activeCountry = countries.find((country) => country.code === countryCode)
  const pathWithoutLocale = isClient ? getRelativeUrlWithoutLocale() : "/"
  const close = () => setOpen(false)

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="hover:opacity-100 flex items-center w-full h-full gap-2 opacity-75"
      >
        <span>{T.shippingTo}:</span>

        {activeCountry ? (
          <>
            <CountryFlag country={activeCountry} />
            {activeCountry.name}
          </>
        ) : (
          <>&nbsp;</>
        )}

        <ArrowRightIcon />
      </button>

      <Modal isOpen={open} close={close} size="small">
        <Modal.Body>
          <CloseButton onClick={close} />

          <div className="flex flex-col items-start justify-center w-full h-full">
            <ul className="flex flex-col gap-1 my-4">
              {countries
                .sort((country) => {
                  return country.code === countryCode ? -1 : 1
                })
                .map((country) => {
                  const href =
                    country.code === DEFAULT_COUNTRY_CODE ? pathWithoutLocale : `/${country.code}${pathWithoutLocale}`

                  return (
                    <li key={country.code}>
                      <a
                        href={href}
                        className="hover:bg-gray-100 active:bg-gray-200 active:scale-95 active:shadow-inner gap-x-2 flex items-center p-2 pl-3 pr-6 rounded-lg cursor-pointer"
                      >
                        <CountryFlag country={country} /> {country.name}
                      </a>
                    </li>
                  )
                })}
            </ul>

            <InfoAlert>{T.helpText}</InfoAlert>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const de = {
  shippingTo: "Versand nach",
  close: "Schließen",
  helpText: "Ein Wechsel des Landes kann eine Anpassung der Steuern, Versandkosten und Preise zur Folge haben.",
}

const en: typeof de = {
  shippingTo: "Shipping to",
  close: "Close",
  helpText: "Changing the country may change the prices, taxes, and shipping costs.",
}

const text = { de, en }
