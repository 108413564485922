import { CSSProperties } from "react"
import { ReactCountryFlag } from "react-country-flag"
import { StorefrontCountry } from "src/types/storefront"

const flagIconStyle: CSSProperties = {
  width: "20px",
  height: "20px",
}

export type CountryFlagProps = {
  country: StorefrontCountry
}

export const CountryFlag = ({ country }: CountryFlagProps) => {
  return <ReactCountryFlag svg style={flagIconStyle} countryCode={country.code} alt={country.name} loading="lazy" />
}
