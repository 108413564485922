"use client"

import { usePrevious } from "ahooks"
import whatsAppIcon from "assets/social/ClickToChat_WhatsApp-Logo_V3.svg"
import clsx from "clsx"
import { usePathname } from "next/navigation"
import { useEffect, useState } from "react"
import { Img } from "src/components/unstyled/Img"
import { useWindowEvent } from "src/hooks/useWindowEvent"
import { localizeText } from "src/localization/utils"
import { META_DATA } from "src/metadata/constants"

export const ClickToChat = () => {
  const T = localizeText(text)
  const [visible, setVisible] = useState(false)
  const pathname = usePathname()
  const previousPathname = usePrevious(pathname)

  useEffect(() => {
    if (pathname !== previousPathname) {
      setVisible(false)
    }
  }, [pathname, previousPathname])

  useWindowEvent("scroll", () => setVisible(window.scrollY > 200))

  return (
    <a
      href={META_DATA.socials.whatsApp}
      target="_blank"
      rel="noopener noreferrer"
      className={clsx(
        "top-12 bg-brand-primary fixed right-0 z-20 flex items-center gap-4 py-2 pl-6 pr-4 text-white rounded-l-full shadow-lg duration-300 border border-white border-r-0 transition-transform",
        {
          "translate-x-0 ease-out": visible,
          "translate-x-full ease-in": !visible,
        },
      )}
    >
      <div>
        <div className="sm:text-lg font-bold !leading-snug uppercase">{T.anyQuestions}</div>
        <div className="sm:text-base text-sm !leading-snug">{T.goToChat}</div>
      </div>
      <Img src={whatsAppIcon.src} alt="WhatsApp" loading="lazy" className="sm:w-12 sm:h-12 w-10 h-10" />
    </a>
  )
}

const de = {
  anyQuestions: "Noch Fragen?",
  goToChat: "Gerne! Zum Chat »",
}

const en: typeof de = {
  anyQuestions: "Any questions?",
  goToChat: "Sure! Go to chat »",
}

const text = { de, en }
