"use client"

import { ArrowRightIcon } from "src/components/icons"
import { ccm19Show } from "src/integrations/ccm19/api"
import { localizeText } from "src/localization/utils"

export const CookieSettingsButton = () => {
  const T = localizeText(text)

  return (
    <button type="button" onClick={ccm19Show} className="opacity-75 hover:opacity-100 flex items-center gap-1">
      {T.cookieSettings}

      <ArrowRightIcon />
    </button>
  )
}

const de = {
  cookieSettings: "Cookie-Einstellungen",
  allRightsReserved: "Alle Rechte vorbehalten",
}

const en: typeof de = {
  cookieSettings: "Cookie settings",
  allRightsReserved: "All rights reserved",
}

const text = { de, en }
