"use client"

import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react"
import clsx from "clsx"
import { FC, ReactNode } from "react"
import { CloseButton } from "src/components/common/CloseButton"
import { ModalProvider, useModal } from "src/context/modal-context"

export type ModalProps = {
  isOpen: boolean
  close: () => void
  size?: "small" | "medium" | "large"
  children: ReactNode
}

export const Modal = ({ isOpen, close, size = "medium", children }: ModalProps) => {
  return (
    <Dialog
      open={isOpen}
      onClose={close}
      transition
      className="fixed inset-0 flex w-screen items-center justify-center transition duration-300 ease-out data-[closed]:opacity-0 bg-gray-900/65"
    >
      <div className="sm:min-h-full sm:h-full sm:w-full fixed inset-0 flex items-center justify-center w-screen h-screen overflow-y-auto text-center">
        <DialogPanel
          transition
          className={clsx(
            "flex flex-col justify-start w-full transform p-6 text-left align-middle sm:max-h-[75vh] h-fit bg-white shadow-xl sm:border sm:rounded-xl transition duration-300 ease-out data-[closed]:opacity-0 data-[closed]:scale-95",
            {
              "max-w-md": size === "small",
              "max-w-xl": size === "medium",
              "max-w-3xl": size === "large",
            },
          )}
        >
          <ModalProvider close={close}>{children}</ModalProvider>
        </DialogPanel>
      </div>
    </Dialog>
  )
}

const Title: FC<{ children: ReactNode }> = ({ children }) => {
  const { close } = useModal()

  return (
    <DialogTitle className="flex items-center justify-between">
      <div className="text-base font-semibold">{children}</div>
      <CloseButton onClick={close} />
    </DialogTitle>
  )
}

const Description: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <div className="text-default flex items-center justify-center h-full pt-2 pb-4 text-xs font-normal">{children}</div>
  )
}

const Body: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="flex justify-center">{children}</div>
}

const Footer: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className="gap-x-4 flex items-center justify-end">{children}</div>
}

Modal.Title = Title
Modal.Description = Description
Modal.Body = Body
Modal.Footer = Footer
