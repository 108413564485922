"use client"

import { useTrackPageViews } from "../tracking/api"
// import { TrustedShopsTag } from "./trustedShops/TrustedShopsTag"
import { AdcellTag } from "./adcell/AdcellTag"
import { Ccm19Tag } from "./ccm19/Ccm19Tag"
import { FraudBlockerTag } from "./fraudBlocker/FraudBlockerTag"
import { GoogleAnalyticsTag } from "./google/GoogleAnalyticsTag"
import { KlaviyoTag } from "./klaviyo/KlaviyoTag"
import { TypeformTag } from "./typeform/TypeformTag"

export const Integrations = () => {
  useTrackPageViews()

  return (
    <>
      <Ccm19Tag />
      <GoogleAnalyticsTag />
      <KlaviyoTag />
      <TypeformTag />
      <AdcellTag />
      <FraudBlockerTag />
      {/* <TrustedShopsTag /> */}
    </>
  )
}
