"use client"

import { runOnceInBrowser } from "src/utilities/runOnceInBrowser"
import { IntegrationScript } from "../lib/IntegrationScript"
import { googleConfig } from "./config"
import { configureGoogleDefaultConsent, configureGoogleTag } from "./configureGoogleTag"

const src = `${googleConfig.taggingServerOrigin}/gtag/js?id=${googleConfig.measurementId}`

export const GoogleAnalyticsTag = () => {
  runOnceInBrowser("configureGoogleTag", () => {
    configureGoogleDefaultConsent()
    configureGoogleTag()
  })

  return <IntegrationScript id="google_analytics" src={src} autoConsent />
}
