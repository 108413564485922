import { IntegrationScript } from "../lib/IntegrationScript"

export const AdcellTag = () => {
  return (
    <IntegrationScript
      id="adcell"
      src="https://t.adcell.com/js/trad.js"
      onLoad={() => {
        if (typeof window.Adcell?.Tracking?.track === "function") {
          window.Adcell.Tracking.track()
        } else {
          console.error("Cannot call window.Adcell.Tracking.track, it is not a function")
        }
      }}
    />
  )
}
