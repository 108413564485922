import { ReactNode } from "react"
import { InfoIcon } from "src/components/icons"

export type InfoAlertProps = {
  children: ReactNode
}

export const InfoAlert = ({ children }: InfoAlertProps) => {
  return (
    <div className="text-subtle flex items-center gap-2 text-sm">
      <InfoIcon />
      {children}
    </div>
  )
}
